import { AuctionItemTitle, AuctionItemDiv, AuctionItemDescription, AuctionItemImage, AuctionItemSellerName, AuctionItemImageLink, AuctionItemWinnerInfo } from './styled/AuctionItemStyles';

export interface IAuctionItem {
    index: number;
    title: string,
    description: string,
    seller: string,
    imageUrl: string,
    sellerMoSpace: string,
    winnerName?: string,
    soldPrice?: string
};

export default function AuctionItem(
    props: IAuctionItem
) {
    const sold = !!(props.soldPrice && props.winnerName);
    return <AuctionItemDiv sold={sold}>
        <AuctionItemTitle>Lot {props.index}. {props.title}</AuctionItemTitle>
        <AuctionItemDescription>{props.description}</AuctionItemDescription>
        {props.imageUrl && <AuctionItemImageLink target="_blank" rel="noreferrer" href={props.imageUrl}><AuctionItemImage alt={props.description} src={props.imageUrl} onError={(e) => (e.target as HTMLPictureElement).style.display = 'none'} /></AuctionItemImageLink>}
        <AuctionItemSellerName>Being sold by <a href={props.sellerMoSpace}>{props.seller}</a></AuctionItemSellerName>
        {props.winnerName && props.soldPrice && <AuctionItemWinnerInfo>Won by <strong>{props.winnerName}</strong> for <strong>{props.soldPrice}</strong></AuctionItemWinnerInfo>}
    </AuctionItemDiv>
}