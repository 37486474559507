import axios from 'axios';
export default class SheetService {

    public async getData() {
        const response = await axios.get(`https://9x0pslg3w6.execute-api.ap-southeast-2.amazonaws.com/listings`);
        if (!response.data) {
            return alert("Couldn't get auction data :( Try again!");
        }
        return response.data;
    }
}