import React from 'react';
import './App.css';
import Auction from './components/Auction';
import styled from 'styled-components';
/* <img src='./movember_logo_primary.svg'/> */

const AppTitle = styled.h1`
  text-transform: uppercase;
  font-size: xxx-large;
  color: #000;
  padding-bottom: 0;
`;

const AppSubline = styled.p`
  text-transform: uppercase;
  font-size: 20px;
  color: #000;
`;

const AppFooterLine = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  color: #000;
`;

function App() {
  return (
    <div className="App">
      <AppTitle>Mo Trash Mo Treasure 2024</AppTitle>
      <AppSubline>
        <img alt="An auctioneer's gavel" src="/gavel.gif" width="24" height="24"/>
        &nbsp;&nbsp;
        <a href="https://docs.google.com/spreadsheets/d/1JxySX0_I66C44MbxYGg4cE8Z7xpj0Q8EKzZG1IDBtGA/edit?gid=0#gid=0" target="MoAuctionsWindow">Click here to add your items for auction!</a>
        &nbsp;&nbsp;
        <img alt="An auctioneer's gavel" src="/gavel.gif" width="24" height="24"/> 
      </AppSubline>
      <AppSubline>This year's Mo Trash Mo Treasure is happening <strong>November 20th, 3PM @ Mo HQ</strong>. If you need help, get in touch with <a href="https://movember.slack.com/archives/DS4R4NW8H" target="_blank" rel="noreferrer">Jarrod</a> on Slack.</AppSubline>
      <Auction />
      <AppFooterLine>Reminder: All sales happen in the form of a donation to the seller's MoSpace.</AppFooterLine>
    </div>
  );
}

export default App;
