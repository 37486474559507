import { useEffect, useState } from "react";
import AuctionItem, { IAuctionItem } from "./AuctionItem";
import SheetService from '../service/SheetService';
import styled from 'styled-components';
import { StyledSpinner, StyledSpinnerWrapper } from "./styled/AuctionItemStyles";

const ss = new SheetService();

const GridWrapperDiv = styled.div`
    --auto-grid-min-size: 16rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    margin: 0;
    padding: 0;
    box-sizing: border-box;
`;

const ErrorDiv = styled.div`
    font-weight: bold;
    color: red;
`;

export default function Auction() {
    const [details, setDetails] = useState<Array<IAuctionItem>>([]);
    const [error, setError] = useState<false | string>(false);

    useEffect(() => {
        try {
            ss.getData()
                .then(data => setDetails(data))
                .catch(e => setError(e.message));
        } catch (e: any) {
            setError(e.message);
        }
    }, []);

    if (error) {
        return <ErrorDiv>
            Sorry! Something went wrong... How embarrassing! Let <a href="https://movember.slack.com/archives/D01QWDT9E2X">Jack Philippi</a> know so he can fix it!
            <br/><br/>
            Error: {error}
        </ErrorDiv>;
    }
    
    let counter = 0;

    if (details.length === 0) {
        return <StyledSpinnerWrapper>
            <StyledSpinner viewBox="0 0 50 50">
                <circle className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="4" />
            </StyledSpinner>
        </StyledSpinnerWrapper>;
    }

    return <GridWrapperDiv>
        {details.map((auc: any) => (auc.length > 0) && (
            <AuctionItem
                index={++counter}
                title={auc[0]}
                description={auc[1]}
                seller={auc[2]}
                sellerMoSpace={auc[3]}
                imageUrl={auc[4]}
                winnerName={auc[5]}
                soldPrice={auc[6]}
            />
        ))}
    </GridWrapperDiv>
}
